import React from 'react'
import styled from 'styled-components'
import { AppVideoBanner } from './AppVideoBanner'
import AnalyticsBannerData from '../Data/AnalyticsBannerData.json'

const AppAnalyticsBannerWapper = styled.div`
  .AnalyticscontainSection {
    /* background-color: #021d2d; */
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 130px 30px 50px 30px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1391px) {
      padding: 130px 20px 50px 20px;
    }
    @media (max-width: 1090px) {
      padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .AnalyticsBannerSectionOne {
    margin-top: 2rem;
    margin-bottom: auto;
    @media (max-width: 1390px) {
      margin-top: auto;
    }
  }
  .AnalyticsBannerHeadingSize {
    /* color: #ffffff; */
    max-width: 666px;
    line-height: 1.30435em;
    padding-bottom: 3%;
    font-size: 2.5rem;
    margin-top: auto;
  }
  .AnalyticsBannerPara {
    max-width: 950px;
    padding: 10px 20px 0px 0px;
  }
  .AnalyticsBannerParaOne {
    color: #047cc2;
    /* color: #ffffff; */
    max-width: 950px;
    padding: 10px 20px 0px 0px;
    font-size: 26px;
    font-weight: bolder;
  }
  .AnalyticsBannerImage {
    width: 100%;
    height: auto;
    // float: right;
    // @media (max-width: 1390px) {
    //   width: 100%;
    //   float: unset;
    // }
  }
  .whyRaindropListView {
    display: none;
  }
  .AnalyticsSectionButton {
    display: flex;
    justify-content: center;
    margin: 40px 0px 20px 0px;
  }
  .AnalyticsBannervideo {
    width: 65%;
    height: auto;
    @media (max-width: 1650px) {
      margin-top: 3%;
    }
    @media (max-width: 1390px) {
      width: 100%;
      margin-top: unset;
    }
  }
  .AnalyticsBannerContent {
    margin-top: 3%;
    @media (max-width: 1650px) {
      margin-top: unset;
    }
  }
`

export const AppAnalyticsBanner = () => {
  return (
    <AppAnalyticsBannerWapper>
      {AnalyticsBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppVideoBanner
              containSection="AnalyticscontainSection"
              EventBannerHeading={data.heading}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              ImagePath={data.ImagePath}
              altName={data.AltName}
              BannerSectionOne="AnalyticsBannerSectionOne"
              BannerSectionTwo="BannerSectionTwo item-2"
              EventBannerHeadingSize="AnalyticsBannerHeadingSize"
              EventBannerParaOne="AnalyticsBannerParaOne"
              EventBannerPara="AnalyticsBannerPara"
              EventBannerImage="AnalyticsBannerImage"
              UlStyle="whyRaindropListView"
              EventBannerSectionTwo="AnalyticsBannervideo"
              EventBannerSectionOne="AnalyticsBannerContent"
            />
          </div>
        )
      })}
    </AppAnalyticsBannerWapper>
  )
}
