import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'

const AppAnalyticsSecondSectionWapper = styled.div`
  max-width: 95vw;
  margin: auto;
  .PayablesHeading {
    font-size: 92px;
    color: #34a1d5;
    /* text-align: left; */
    /* padding: 0px 0px 10px 0px; */
  }
  .PayablesKeyPara {
    /* text-align: left; */
    max-width: 1200px;
    /* margin: auto; */
    display: flex;
    font-weight: bolder;
    color: #fff;
    /* padding-top: 24px; */
    /* padding-bottom: 50px; */
    /* margin: 0px 200px 0px 200px; */
    @media (max-width: 1090px) {
      padding: 10px 15px 50px 15px;
      font-size: 16px;
    }
  }
  /* @media (max-width: 1920px) {
      padding: 0px 15px 50px 15px;
      margin: 0px 0px 0px 400px;
    }
    @media (max-width: 1650px) {
      padding: 0px 15px 50px 15px;
      margin: 0px 0px 0px 250px;
    }
    @media (max-width: 1440px) {
      padding: 0px 15px 50px 15px;
      margin: 0px 180px 0px 180px;
    } */

  .PayablesSecondSectionContainer {
    /* background-image: url(/img/payable-banner-3.png);
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; */
    height: 45vh;
    background-color: #102c64;
    border-radius: 42px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    /* @media (max-width: 1090px) {
      padding: 0px 0px 50px 0px;
    } */
    @media (max-width: 440px) {
      display: flex;
      flex-direction: column;
      height: auto;
    }
  }
  .CardImage {
    width: auto;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 12px;
    margin-bottom: 24px;
  }
  .PayablesSecondSection {
    padding: 0px 42px 0px 42px;
  }
`

export const AppAnalyticsSecondSection = () => {
  return (
    <AppAnalyticsSecondSectionWapper>
      <div className="PayablesSecondSectionContainer">
        <div className="PayablesSecondSection">
          <ReUsePtag para="Identify Risks And Opportunities" paraStyle="PayablesKeyPara" />
          <img src="https://storage.googleapis.com/raindroppublic/website_data/HomeFree.png" className="CardImage" />
        </div>
        <div className="PayablesSecondSection">
          <ReUsePtag para="Track Spend to Contract Obligations" paraStyle="PayablesKeyPara" />
          <img src="https://storage.googleapis.com/raindroppublic/website_data/Finance.png" className="CardImage" />
        </div>
        <div className="PayablesSecondSection">
          <ReUsePtag para="Measure the Effectiveness of Supplier ESG Programs" paraStyle="PayablesKeyPara" />
          <img src="https://storage.googleapis.com/raindroppublic/website_data/Procurement.png" className="CardImage" />
        </div>
        <div className="PayablesSecondSection">
          <ReUsePtag para="Transform from Reactive to Predictive Spend Management" paraStyle="PayablesKeyPara" />
          <img src="/img/Analytics-predictive.png" className="CardImage" />
        </div>
      </div>
    </AppAnalyticsSecondSectionWapper>
  )
}
